/*!
 * SlickNav Responsive Mobile Menu v1.0.3
 * (c) 2015 Josh Cope
 * licensed under MIT
 */
.slicknav_btn {
  cursor: pointer;
  display: block;
  float: right;
  height: 35px;
  line-height: 50px;
  padding: 10px 0 9px;
  position: relative;
  vertical-align: middle;
  width: 44px;
}
  .slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
    margin-top: 0.188em; }

.slicknav_menu {
  *zoom: 1; }
  .slicknav_menu .slicknav_menutxt {
    display: block;
    line-height: 1.188em;
    float: left; }
  .slicknav_menu .slicknav_icon {
    margin: 0.188em 0 0 0.438em; }
  .slicknav_menu .slicknav_no-text {
    margin: 0; }
  .slicknav_menu .slicknav_icon-bar {
    display: block;
    width: 1.125em;
    height: 0.125em;
    margin: 0 auto;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  .slicknav_menu:before {
    content: " ";
    display: table; }
  .slicknav_menu:after {
    content: " ";
    display: table;
    clear: both; }

.slicknav_nav {
  clear: both; }
  .slicknav_nav ul {
    display: block; }
  .slicknav_nav li {
    display: block; 
    line-height: 30px;
  }
  .slicknav_nav li:not(:first-child){
  }
  .slicknav_nav .slicknav_arrow {
    background: #ededed none repeat scroll 0 0;
    float: right;
    text-align: center;
    width: 35px;
    color: #666;
  }
  .slicknav_nav .slicknav_item {
    cursor: pointer; }
    .slicknav_nav .slicknav_item a {
      display: inline; }
  .slicknav_nav .slicknav_row {
    display: block; }
  .slicknav_nav a {
    display: block; }
  .slicknav_nav .slicknav_parent-link a {
    display: inline; }

.slicknav_brand {
  float: left; }

.slicknav_menu {
  font-size: 16px;
  box-sizing: border-box;
}
.slicknav_menu ul{
  background: #fff;
}
  .slicknav_menu * {
    box-sizing: border-box; }
  .slicknav_menu .slicknav_menutxt {
    color: #fff;
    font-weight: bold;
    text-shadow: 0 1px 3px #000; }

.slicknav_btn {
  border: 1px solid;
  border-radius: 0;
  margin: 11px 15px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.slicknav_nav {
  color: #fff;
  font-size: 0.875em;
  list-style: outside none none;
  margin: 0;
  max-height: 350px;
  padding: 0;
  width: 100%;
}
  .slicknav_nav ul {
    list-style: none;
    overflow: hidden;
    padding: 0;
    margin: 0 0 0 20px; }
  .slicknav_nav .slicknav_row {
    padding: 5px 10px;
    margin: 2px 5px; }
  .slicknav_nav a {
    padding: 5px 10px;
    margin: 2px 5px;
    text-decoration: none;
    color: #666; }
  .slicknav_nav .slicknav_txtnode {
    margin-left: 15px; }
  .slicknav_nav .slicknav_item a {
    padding: 0;
    margin: 0; }
  .slicknav_nav .slicknav_parent-link a {
    padding: 0;
    margin: 0; }

.slicknav_brand {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  padding: 7px 12px;
  height: 44px; }
