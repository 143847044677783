@import "_variables.scss";
@import "_fonts.scss";

@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import "~bootstrap/scss/bootstrap";

@import "_typography.scss";
@import "_lib.scss";
@import "_block.scss";


@import "lib/animate.css";
@import "lib/magnific-popup.css";

@import "lib/responsive.css";
@import "lib/slicknav.css";


@import "_main.scss";
